import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/react-scroll-utilities/react-scroll-utilities/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from "docz";
import { useVirtualizedList } from "./useVirtualizedList";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "playground"
    }}>{`Playground`}</h2>
    <Playground __position={0} __code={'() => {\n  const length = 1000\n  const height = 250\n  const overscan = 50\n  //\n  const items = Array.from({ length }, (_, index) => index)\n  //\n  const [{ ref, onScroll }, virtualItems] = useVirtualizedList(items, {\n    initialContainerSize: height,\n    overscan,\n  })\n  //\n  return (\n    <div ref={ref} onScroll={onScroll} style={{ overflowY: \'auto\', height }}>\n      {virtualItems.map(({ ref, key, item, style }) => (\n        <div ref={ref} key={key} style={style}>\n          item <span style={{ fontSize: 32 }}>{item}</span>\n        </div>\n      ))}\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      useVirtualizedList,
      DefaultLayout,
      _frontmatter
    }} scope={{
      useVirtualizedList
    }} mdxType="Playground">
	{() => {
        const length = 1000;
        const height = 250;
        const overscan = 50; //

        const items = Array.from({
          length
        }, (_, index) => index); //

        const [{
          ref,
          onScroll
        }, virtualItems] = useVirtualizedList(items, {
          initialContainerSize: height,
          overscan
        }); //

        return <div ref={ref} onScroll={onScroll} style={{
          overflowY: "auto",
          height
        }}>
				{virtualItems.map(({
            ref,
            key,
            item,
            style
          }) => <div ref={ref} key={key} style={style}>
						item <span style={{
              fontSize: 32
            }}>{item}</span>
					</div>)}
			</div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      